import React from "react"
import * as Styles from "./index.module.scss"

import banner from "../../images/korea/banner/banner_koreajp_jp.jpg"
import bannerw from "../../images/korea/banner/banner_koreajp_jp.webp"
import bannerSp from "../../images/korea/banner/banner_koreajp_jp_sp.jpg"
import bannerSpw from "../../images/korea/banner/banner_koreajp_jp_sp.webp"

const BannerComponent = () => {
  return (
    <div className={Styles.topBanner}>
      <picture>
        <source media="(max-width: 767px)" srcSet={bannerSpw} />
        <source media="(max-width: 767px)" srcSet={bannerSp} />
        <source srcSet={bannerw} />
        <img
          src={banner}
          alt="協業パートナー募集中！日本企業様の韓国進出を一緒に支援してくださる韓国企業やフリーランスのパートナー様を募集しています。ぜひ一度お話ししましょう。お気軽にお問い合わせください。"
          width="688"
          height="216"
          className={Styles.topBannerImg}
        />
      </picture>
    </div>
  )
}

export default BannerComponent
